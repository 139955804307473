import React from 'react';
import classNames from 'classnames';
import withLayoutColorClasses from '../../hoc/with-layout-color-classes';
import styles from './separator.scss';

type HorizontalSeparatorProps = {
  className?: string;
  inverted?: boolean;
  separatorBackgroundColorClassName?: string;
};

export const HorizontalSeparator = ({
  className,
  inverted,
  separatorBackgroundColorClassName,
}: HorizontalSeparatorProps) => (
  <span
    className={classNames(
      styles.horizontal,
      className,
      getClassName(inverted, separatorBackgroundColorClassName),
    )}
  />
);

export const HorizontalSeparatorForPostCard = withLayoutColorClasses(HorizontalSeparator);

function getClassName(inverted?: boolean, separatorBackgroundColorClassName?: string) {
  return inverted
    ? 'blog-inverted-separator-background-color'
    : separatorBackgroundColorClassName || 'blog-separator-background-color';
}

type BulletSeparatorProps = {
  className?: string;
  inverted: boolean;
  separatorBackgroundColorClassName: string;
};

const BulletSeparator = ({
  className,
  inverted,
  separatorBackgroundColorClassName,
}: BulletSeparatorProps) => (
  <span
    className={classNames(
      styles.bullet,
      className,
      getClassName(inverted, separatorBackgroundColorClassName),
    )}
  />
);

export const BulletSeparatorForPost = withLayoutColorClasses(BulletSeparator);
